import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/legal.scss';

export const LegalPage = ({ }) => {
    return( 
        <main className='legal-content'>
            <Helmet>
                <title>PhantomTutor - Legal</title>
            </Helmet>
            
            <section>
                <h1><a id='terms'>Terms of Service</a></h1>

                <i>Last updated: December 15, 2022</i><br/><br/>

                
                From everyone at Phantom Software, thank you for using our products! We build them to help you do your best work. Because we don’t know every one of our customers personally, we have to put in place some Terms of Service to help keep the ship afloat.
                <br/>
                When we say “Company”, “we”, “our”, or “us” in this document, we are referring to Phantom Software LLC. 
                <br/>
                When we say “Services”, we mean any product created and maintained by Phantom Software LLC. Which currently only includes PhantomTutor: whether delivered within a web browser, desktop application, mobile application, or another format.
                <br/>
                When we say “You” or “your”, we are referring to the people or organizations that own an account with one our Service.
                <br/>
                We may update these Terms of Service in the future. Whenever we make a significant change to our policies, we will refresh the date at the top of this page and take any other appropriate steps to notify account holders.
                <br/>
                When you use our Services, now or in the future, you are agreeing to the latest Terms of Service. 
                That’s true for any of our existing and future products and all features that we add to our Services over time. 
                There may be times where we do not exercise or enforce any right or provision of the Terms of Service; 
                in doing so, we are not waiving that right or provision. <b>These terms do contain a limitation of our liability. </b>
                <br/>
                If you violate any of the terms, we may terminate your account. That’s a broad statement and it means you need to place a lot of trust in us. We will do our best to preserve that trust and be open and communicative in case of any potential violations.
                <br/>
                <br/>
                <h2>Account Terms</h2>

                <ol>
                    <li>
                        You are responsible for maintaining the security of your account and password. 
                        The Company cannot and will not be liable for any loss or damage from your failure to comply with this security obligation. 
                    </li>
                    <li>You may not use the Services for any purpose related to illegal activity, academic dishonesty, spam, or other questionable behavior.</li>
                    <li>You are responsible for all activity that occurs under your account. That includes content posted by others who either: (a) have access to your login credentials; or (b) have their own logins under your account.</li>
                    <li>You must be a human. Accounts registered by “bots” or other automated methods are not permitted.</li>
                </ol>

                <h2>Payment, Refunds, and Plan Changes</h2>

                <ol>
                    <li>If you are upgrading from a plan to a higher-tier plan, the new rate starts from the next billing cycle.</li>
                    <li>All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities. Where required, we will collect those taxes on behalf of the taxing authority and remit those taxes to taxing authorities. See our <a href='#' id='../taxes/index.md'>Taxes policy</a> for more details. Otherwise, you are responsible for payment of all taxes, levies, or duties.</li>
                </ol>

                <h2>Cancellation and Termination</h2>

                <ol>
                    <li>You are solely responsible for properly canceling your account. Within each our Services, we provide a simple no-questions-asked cancellation link. An email or phone request to cancel your account is not automatically considered cancellation. If you need help canceling your account, you can always <a href='mailto:info@phantom-soft.com'>contact our Support team</a>.</li>
                    <li>If you cancel the Service before the end of your current paid up term, your cancellation will take effect at the end of the term, and you will not be charged again. We do not automatically prorate unused time in the last billing cycle.</li>
                    <li>We have the right to suspend or terminate your account and refuse any and all current or future use of our Services for any reason at any time. Suspension means you and any other users on your account will not be able to access the account or any content in the account. Termination will furthermore result in the deletion of your account or your access to your account, and the forfeiture and relinquishment of all content in your account. We also reserve the right to refuse the use of the Services to anyone for any reason at any time. We have this clause because statistically speaking, out of the hundreds of thousands of accounts on our Services, there is at least one doing something nefarious. There are some things we staunchly stand against and this clause is how we exercise that stance.</li> 
                    <li>Verbal, physical, written or other abuse (including threats of abuse or retribution) of Company employee or officer will result in immediate account termination.</li>
                </ol>

                <h2>Modifications to the Service and Prices</h2>

                <ol>
                    <li>We make a promise to our customers to support our Services as long as possible. Sometimes it becomes technically impossible to continue a feature or we redesign a part of our Services because we think it could be better or we decide to close new signups of a product. We reserve the right at any time to modify or discontinue, temporarily or permanently, any part of our Services with or without notice.</li>
                    <li>Sometimes we change the pricing structure for our products. When we do that, we tend to exempt existing customers from those changes. However, we may choose to change the prices for existing customers. If we do so, we will give at least 30 days notice and will notify you via the email address on record. We may also post a notice about changes on our websites or the affected Services themselves.</li>
                </ol>

                <h2>Uptime, Security, and Privacy</h2>

                <ol>
                    <li>Your use of the Services is at your sole risk. We provide these Services on an “as is” and “as available” basis. We do not offer service-level agreements for our Service but do take uptime of our applications seriously.</li>
                    <li>We reserve the right to temporarily disable your account if your usage significantly exceeds the average usage of other customers of the Services. Of course, we’ll reach out to the account owner before taking any action except in rare cases where the level of use may negatively impact the performance of the Service for other customers.</li>
                    <li>When you use our Services, you entrust us with your data. We take that trust to heart. You agree that Phantom Software may process your data as described in our <a href='#privacy-policy'>Privacy Policy</a> and for no other purpose. We as humans can access your data for the following reasons:
                    <ul>
                        <li><i>To help you with support requests you make.</i> We’ll ask for express consent before accessing your account.</li>
                        <li><i>On the rare occasions when an error occurs that stops an automated process partway through.</i> We get automated alerts when such errors occur. When we can fix the issue and restart automated processing without looking at any personal data, we do. In rare cases, we have to look at a minimum amount of personal data to fix the issue. In these rare cases, we aim to fix the root cause as much as possible to avoid the errors from reoccurring.</li>
                        <li><i>To safeguard Phantom Software.</i> We’ll look at logs and metadata as part of our work to ensure the security of your data and the Services as a whole. If necessary, we may also access accounts as part of an abuse report investigation.</li>
                        <li><i>To the extent required by applicable law.</i> As a US company with all data infrastructure located in the US, we only preserve or share customer data if compelled by a US government authority with a legally binding order or proper request under the Stored Communications Act. If a non-US authority approaches Phantom Software for assistance, our default stance is to refuse unless the order has been approved by the US government, which compels us to comply through procedures outlined in an established mutual legal assistance treaty or agreement mechanism. If Phantom Software is audited by a tax authority, we only share the bare minimum billing information needed to complete the audit.</li>
                    </ul>
                    </li>
                    <li>We use third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Services. </li>
                    <li>Under the California Consumer Privacy Act (“CCPA”), Phantom Software is a “service provider”, not a “business” or “third party”, with respect to your use of the Services. That means we process any data you share with us only for the purpose you signed up for and as described in these Terms of Service and <a href='#privacy-policy'>Privacy policy</a>. We do not retain, use, disclose, or sell any of that information for any other commercial purposes unless we have your explicit permission. And on the flip-side, you agree to comply with your requirements under the CCPA and not use our Services in a way that violates the regulations.</li>
                </ol>


                <h2>Copyright and Content Ownership</h2>

                <ol>
                    <li>All content posted on the Services must comply with U.S. copyright law. </li>
                    <li>We claim no intellectual property rights over the material you provide to the Services. All materials uploaded remain yours.</li>
                    <li>We do not pre-screen content, but reserve the right (but not the obligation) in our sole discretion to refuse or remove any content that is available via the Service.</li>
                    <li>The names, look, and feel of the Services are copyright© to the Company. All rights reserved. You may not duplicate, copy, or reuse any portion of the HTML, CSS, JavaScript, or visual design elements without express written permission from the Company. You must request permission to use the Company’s logo or any Service logos for promotional purposes. Please <a href='mailto:info@phantom-soft.com'>email us</a> requests to use logos. We reserve the right to rescind this permission if you violate these Terms of Service.</li>
                    <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Services, use of the Services, or access to the Services without the express written permission by the Company.</li>
                    <li>You must not modify another website so as to falsely imply that it is associated with the Services or the Company.</li>
                </ol>
                
                <h2>Features and Bugs</h2>

                We design our Services with care, based on our own experience and the experiences of customers who share their time and feedback. However, there is no such thing as a service that pleases everybody. We make no guarantees that our Services will meet your specific requirements or expectations.
                <br/>
                We also test all of our features extensively before shipping them. As with any software, our Services inevitably have some bugs. We track the bugs reported to us and work through priority ones, especially any related to security or privacy. Not all reported bugs will get fixed and we don’t guarantee completely error-free Services.
                <br/>
                Our open beta software can, and likely will, have bugs. In exchange for this rough experience, we try to provide a low price you can lock in even when we leave beta as a symbol of our appreciation of your support.
                <br/><br/>
                <h2>Liability</h2>

                We mention liability throughout these Terms but to put it all in one section:
                
                <br/>
                <b><i>You expressly understand and agree that the Company shall not be liable, in law or in equity, to you or to any third party for any direct, indirect, incidental, lost profits, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if the Company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the Services; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to this Terms of Service or the Services, whether as a breach of contract, tort (including negligence whether active or passive), or any other theory of liability.</i></b>
                <br/>
                
                In other words: choosing to use our Services does mean you are making a bet on us. If the bet does not work out, that’s on you, not us. We do our best to be as safe a bet as possible through careful management of the business; investments in security, infrastructure, and talent; and in general. If you choose to use our Services, thank you for betting on us.
                <br/>
                If you have a question about any of the Terms of Service, please <a href='mailto:info@phantom-soft.com'>contact our Support team</a>.
            </section>
            
            <br/>

            <section>
                <h1><a id='privacy-policy'>Privacy Policy</a></h1>

                <i>Last updated: December 15, 2022</i>
                <br/><br/>

                The privacy of your data—and it is your data, not ours!—is a big deal to us. In this policy, we lay out: what data we collect and why; how your data is handled; and your rights with respect to your data. We promise we never sell your data: never have, never will.
                <br/>
                This policy applies to all products built and maintained by Phantom Software LLC including only Phantom Tutor (so far).
                <br/><br/>

                <h2>What we collect and why</h2>

                Our guiding principle is to collect only what we need. Here’s what that means in practice:
                <br/><br/>
                <h2>Identity & access</h2>

                When you sign up for a Phantom Software product, we ask for identifying information such as your name, email address, phone number, and maybe a company name. That’s so you can personalize your new account, and we can send you product updates and other essential information. We may also send you optional surveys from time to time to help us understand how you use our products and to make improvements. With your consent, we will send you our newsletter and other updates. We sometimes also give you the option to add a profile picture that displays in our products.
                <br/>
                We’ll never sell your personal information to third parties, and we won’t use your name or company in marketing statements without your permission either.
                <br/><br/>
                <h2>Billing information</h2>

                If you sign up for a paid Phantom Software product, you will be asked to provide your payment information and billing address. Credit card information is submitted directly to our payment processor (typically Stripe) and doesn’t hit Phantom Software servers. We may store a record of the payment transaction, including the last 4 digits of the credit card number, for purposes of account history, invoicing, and billing support. We may store your billing address so we can charge you for service, calculate any sales tax due, send you invoices, and detect fraudulent credit card transactions. We occasionally use aggregate billing information to guide our marketing efforts.
                <br/><br/>
                <h2>Product interactions</h2>

                We store on our servers the content that you upload or receive or maintain in your Phantom Software product accounts. This is so you can use our products as intended. We keep this content as long as your account is active. If you delete your account, we’ll delete the content within 60 days. 
                <br/><br/>
                <h2>Geolocation data</h2>

                For most of our products, we log the full IP address used to sign up a product account and retain that for use in mitigating future spammy signups. We also log all account access by full IP address for security and fraud prevention purposes, and we keep this login data for as long as your product account is active.
                <br/><br/>
                <h2>Website interactions</h2>

                We collect information about your browsing activity for analytics and statistical purposes such as conversion rate testing and experimenting with new product designs. This includes, for example, your browser and operating system versions, your IP address, which web pages you visited and how long they took to load, and which website referred you to us. If you have an account and are signed in, these web analytics data are tied to your IP address and user account until your account is no longer active. The web analytics we use are described further in the Advertising and Cookies section.
                <br/><br/>
                <h2>Anti-bot assessments</h2>

                We use third-party <a href='https://en.wikipedia.org/wiki/CAPTCHA'>CAPTCHA</a> services 
                across our applications to mitigate brute force logins and as a means of spam protection. 
                We have a legitimate interest in protecting our apps and the broader Internet community 
                from credential stuffing attacks and spam. When you log into your Phantom Software accounts, 
                the CAPTCHA service evaluates various information (e.g., IP address, how long the visitor has been on the app, mouse movements) 
                to try to detect if the activity is from an automated program instead of a human. We retain these data via our subprocessor indefinitely for use in spam mitigation.
                <br/><br/>
                <h2>Advertising and Cookies</h2>

                Phantom Software runs contextual ads on various third-party platforms such as Google, Reddit, Instagram, and LinkedIn. Users who click on one of our ads will be sent to the PhantomTutor marketing site. Where permissible under law, we may load an ad-company script on their browsers that sets a third-party cookie and sends information to the ad network to enable evaluation of the effectiveness of our ads, e.g., which ad they clicked and which keyword triggered the ad, and whether they performed certain actions such as clicking a button or submitting a form.
                <br/>
                We also use persistent first-party cookies and some third-party cookies to store certain preferences, make it easier for you to use our applications, and perform A/B testing as well as support some analytics. 
                <br/>
                A cookie is a piece of text stored by your browser. It may help remember login information and site preferences. It might also collect information such as your browser type, operating system, web pages visited, duration of visit, content viewed, and other click-stream data. You can adjust cookie retention settings and accept or block individual cookies in your browser settings, although our apps won’t work and other aspects of our service may not function properly if you turn cookies off.
                <br/><br/>
                <h2>Voluntary correspondence</h2>

                When you email Phantom Software with a question or to ask for help, we keep that correspondence, including your email address, so that we have a history of past correspondence to reference if you reach out in the future.
                <br/>
                We also store information you may volunteer, for example, written responses to surveys. If you agree to a customer interview, we may ask for your permission to record the conversation for future reference or use. We will only do so with your express consent.
                <br/><br/>
                <h2>How we approach mobile app permissions</h2>

                We offer optional desktop and mobile apps for some of our products. Because of how the platforms are designed, our apps typically must request your consent before accessing contacts, calendar, camera, and other privacy-sensitive features of your device. Consent is always optional and our apps will function without it, though some features may be unavailable. There are a few exceptions, for example:
                <ul>
                    <li>Our iOS apps will ask for permission to use push notifications upon first sign-in.</li>
                    <li>Android apps do not require permission to send push notifications.</li>
                </ul>

                <h2>When we access or share your information</h2>

                <i>To provide products or services you’ve requested</i>. We use some third-party subprocessors to help run our applications and provide the Services to you. You can view the third-party subprocessors we use for each of our products: <a href='#'>PhantomTutor</a>. We also use third-party processors for other business functions such as managing newsletter subscriptions, sending customer surveys, and providing our company storefront. 
                We may share your information at your direction if you integrate a third-party service into your use of our products. 
                
                No Phantom Software human looks at your content except for limited purposes with your express permission, for example, if an error occurs that stops an automated process from working and requires manual intervention to fix. These are rare cases, and when they happen, we look for root cause solutions as much as possible to avoid them recurring. We may also access your data if required in order to respond to legal process (see “When required under applicable law” below).
                <br/><br/>
                <i>To exclude you from seeing our ads.</i>  Where permissible by law and if you have a PhantomTutor account, we may share a one-way hash of your email address with ad companies to exclude you from seeing our ads.
                <br/><br/>
                <i>To help you troubleshoot or squash a software bug, with your permission.</i> If at any point we need to access your content to help you with a support case, we will ask for your consent before proceeding.
                <br/><br/>
                <i>To investigate, prevent, or take action regarding <a href='#use-restrictions'>restricted uses</a>.</i> Accessing a customer’s account when investigating potential abuse is a measure of last resort. We want to protect the privacy and safety of both our customers and the people reporting issues to us, and we do our best to balance those responsibilities throughout the process. If we discover you are using our products for a restricted purpose, we will take action as necessary, including notifying appropriate authorities where warranted.
                <br/><br/>
                <i>When required under applicable law. </i>

                Phantom Software is a U.S. company and all data infrastructure are located in the U.S.
                <ul>
                    <li>Requests for user data. Our policy is to not respond to government requests for user data unless we are compelled by legal process or in limited circumstances in the event of an emergency request. However, if U.S. law enforcement authorities have the necessary warrant, criminal subpoena, or court order requiring us to share data, we must comply. Likewise, we will only respond to requests from government authorities outside the U.S. if compelled by the U.S. government through procedures outlined in a mutual legal assistance treaty or agreement. It is 37signals’ policy to notify affected users before we share data unless we are legally prohibited from doing so, and except in some emergency cases.</li>
                    <li>Preservation requests. Similarly, Phantom Software’s policy is to comply with requests to preserve data only if compelled by the U.S. Federal Stored Communications Act, 18 U.S.C. Section 2703(f), or by a properly served U.S. subpoena for civil matters. We do not share preserved data unless required by law or compelled by a court order that we choose not to appeal. Furthermore, unless we receive a proper warrant, court order, or subpoena before the required preservation period expires, we will destroy any preserved copies of customer data at the end of the preservation period.</li>
                    <li>If we are audited by a tax authority, we may be required to share billing-related information. If that happens, we will share only the minimum needed, such as billing addresses and tax exemption information.</li>
                </ul>

                Finally, if Phantom Software LLC is acquired by or merges with another company — we don’t plan on that, but if it happens — we’ll notify you well before any of your personal information is transferred or becomes subject to a different privacy policy.
                <br/><br/>
                <h2>Your rights with respect to your information</h2>

                At Phantom Software, we strive to apply the same data rights to all customers, regardless of their location. Some of these rights include:

                <ul>
                    <li>**Right to Know.** You have the right to know what personal information is collected, used, shared or sold. We outline both the categories and specific bits of data we collect, as well as how they are used, in this privacy policy.</li>
                    <li>**Right of Access.** This includes your right to access the personal information we gather about you, and your right to obtain information about the sharing, storage, security and processing of that information.</li>
                    <li>**Right to Correction.** You have the right to request correction of your personal information.</li>
                    <li>**Right to Erasure / “To Be Forgotten”.** This is your right to request, subject to certain limitations under applicable law, that your personal information be erased from our possession and, by extension, from all of our service providers. Fulfillment of some data deletion requests may prevent you from using Phantom Software services because our applications may then no longer work. In such cases, a data deletion request may result in closing your account.</li>
                    <li>**Right to Complain.** You have the right to make a complaint regarding our handling of your personal information with the appropriate supervisory authority.</li>
                    <li>**Right to Restrict Processing.** This is your right to request restriction of how and why your personal information is used or processed, including opting out of sale of personal information. (Again: we never have and never will sell your personal data.)</li>
                    <li>**Right to Object.** You have the right, in certain situations, to object to how or why your personal information is processed.</li>
                    <li>**Right to Portability.** You have the right to receive the personal information we have about you and the right to transmit it to another party.  If you want to export data from your accounts, you can do so by contacting us at <a href='mailto:info@phantom-soft.com'>info@phantom-soft.com</a> and we will send you your data in a timely manner.</li>
                    <li>**Right to not Be Subject to Automated Decision-Making.** You have the right to object to and prevent any decision that could have a legal or similarly significant effect on you from being made solely based on automated processes. This right is limited if the decision is necessary for performance of any contract between you and us, is allowed by applicable law, or is based on your explicit consent.</li>
                    <li>**Right to Non-Discrimination.** We do not and will not charge you a different amount to use our products, offer you different discounts, or give you a lower level of customer service because you have exercised your data privacy rights. However, the exercise of certain rights may, by virtue of your exercising those rights, prevent you from using our Services.</li>
                </ul>

                Many of these rights can be exercised by signing in and updating your account information.
                <br/>
                If you have questions about exercising these rights or need assistance, please contact us at <a href='mailto:info@phantom-soft.com'>info@phantom-soft.com</a>. If an authorized agent is corresponding on your behalf, we will need written consent with a signature from the account holder before proceeding.
                <br/>
                If you are in the EU or UK, you can contact your data protection authority to file a complaint or learn more about local privacy laws.
                <br/><br/>
                <h2>How we secure your data</h2>

                All data is encrypted via <a href='https://en.wikipedia.org/wiki/Transport_Layer_Security'>SSL/TLS</a> when transmitted from our servers to your browser. The database backups are also encrypted. In addition, we go to great lengths to secure your data at rest. Most data are not encrypted while they live in our database (since they need to be ready to send to you when you need them).
                <br/><br/>
                <h2>What happens when you delete content in your product accounts</h2>

                In many of our applications, we may give you the option to trash content. Anything you trash in your product accounts while they are active will be kept in an accessible trash can for about 25 days (it varies a little by product). After that time, the trashed content cannot be accessed via the application and we are not able to retrieve it for you. The trashed content may remain on our active servers for another 30 days, and copies of the content may be held in backups of our application databases for up to another 30 days after that. Altogether, any content trashed in your product accounts should be purged from all of our systems and logs within 90 days.
                <br/>
                If you choose to cancel your account, your content will become immediately inaccessible and should be purged from our systems in full within 60 days. This applies both for cases when an account owner directly cancels and for auto-canceled accounts.
                <br/><br/>
                <h2>Location of site and data</h2>

                Our products and other web properties are operated in the United States. If you are located in the European Union, UK, or elsewhere outside of the United States, **please be aware that any information you provide to us will be transferred to and stored in the United States**. By using our websites or Services and/or providing us with your personal information, you consent to this transfer.

                <br/><br/>
                <h2>Changes & questions</h2>

                We may update this policy as needed to comply with relevant regulations and reflect any new practices. Whenever we make a significant change to our policies, we will refresh the date at the top of this page and take any other appropriate steps to notify users.
                <br/>
                Have any questions, comments, or concerns about this privacy policy, your data, or your rights with respect to your information? Please get in touch by emailing us at <a href='mailto:info@phantom-soft.com'>info@phantom-soft.com</a> and we’ll be happy to try to answer them!
            </section>
            
            <br/><br/>

            <section>
                <h1><a id='use-restrictions'>Use Restrictions</a></h1>

                <i>Last updated: December 15, 2022</i>
                <br/><br/>

                Many people use Phantom Software products*. We take pride in creating software that makes life easier and respects user privacy. We also recognize that however good the maker’s intentions, technology can amplify the ability to cause great harm. That’s why we’ve established this policy. We feel an ethical obligation to counter such harm: both in terms of dealing with instances where Phantom Software's work is used (and abused) to further such harm, and to state unequivocally that the products we make at Phantom Software are not safe havens for people who wish to commit such harm. If you have an account with any of our products, you can’t use them for any of the restricted purposes listed below. If we find out you are, <a href='#handle-abuse'>we will take action</a>.
                <br/><br/>
                <h2>Restricted Purposes</h2>

                <ul>
                    <li><i>Violence, or threats thereof</i>: If an activity qualifies as violent crime in the United States or where you live, you may not use Phantom Software products to plan, perpetrate, or threaten that activity.</li>
                    <li><i>Child exploitation, sexualization, or abuse</i>: We don’t tolerate any activities that create, disseminate, or otherwise cause child abuse. Keep away and stop. Just stop.</li>
                    <li><i>Hate speech</i>: You cannot use our products to advocate for the extermination, domination, or oppression of people.</li>
                    <li><i>Harassment</i>: Intimidating or targeting people or groups through repeated communication, including using racial slurs or dehumanizing language, is not welcome at Phantom Software.</li>
                    <li><i>Doxing</i>: If you are using Phantom Software products to share other peoples’ private personal information for the purposes of harassment, we don’t want anything to do with you.</li>
                    <li><i>Malware or spyware</i>: Code for good, not evil. If you are using our products to make or distribute anything that qualifies as malware or spyware — including remote user surveillance — begone.</li>
                    <li><i>Phishing or otherwise attempting fraud</i>: It is not okay to lie about who you are or who you affiliate with to steal from, extort, or otherwise harm others.</li>
                    <li><i>Spamming</i>: No one wants unsolicited commercial emails or messages. We don’t tolerate folks (including their bots) using Phantom Software products for spamming purposes of any kind. If your emails don’t pass muster with <a href='https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business'>CAN-SPAM</a> or any other anti-spam law, it’s not allowed.</li>
                    <li><i>Cybersquatting</i>: We don’t like username extortionists. If you purchase a Phantom Software product account in someone else’s name and then try to sell that account to them, you are <a href='https://www.law.cornell.edu/uscode/text/15/1125'>cybersquatting</a>. Cybersquatting accounts are subject to immediate cancellation.</li>
                    <li><i>Infringing on intellectual property</i>: You can’t use Phantom Software products to make or disseminate work that uses the intellectual property of others beyond the bounds of <a href='https://www.copyright.gov/fair-use/more-info.html'>fair use</a>.</li>
                </ul>

                While our use restrictions are comprehensive, they can’t be exhaustive — it’s possible an offense could defy categorization, present for the first time, or illuminate a moral quandary we hadn’t yet considered. That said, we hope the overarching spirit is clear: Phantom Software products are not to be harnessed for harm, whether mental, physical, personal or civic. Different points of view — philosophical, religious, and political — are welcome, but ideologies like racial nationalism, or hate-fueled movements anchored by oppression, violence, abuse, extermination, or domination of one group over another, will not be accepted here.
                <br/><br/>
                <h2>How to report abuse</h2>

                For cases of suspected malware, spyware, phishing, spamming, and cybersquatting, please alert us at <a href='mailto:info@phantom-soft.com'>info@phantom-soft.com</a>.

                For all other cases, please let us know by emailing us as well. If you’re not 100% sure if something rises to the level of our use restrictions policy, report it anyway.

                Please share as much as you are comfortable with about the account, the content or behavior you are reporting, and how you found it. Sending us a URL or screenshots is super helpful. If you need a secure file transfer, let us know and we will send you a link. We will not disclose your identity to anyone associated with the reported account. 

                Someone on our team will respond within one business day to let you know we’ve begun investigating. We have published details on <a href='#handle-abuse'>how we investigate use restriction reports</a>. We will also let you know the outcome of our investigation (unless you ask us not to, or we are not allowed to under law).

                *<i>This policy and process applies to any product created and owned by Phantom Software. That includes PhantomTutor and any upcoming products</i>
            </section>

            <br/><br/>

            <section>
                <h1><a id='handle-abuse'>How We Handle Abuse</a></h1>

                <i>Last updated: December 15, 2022</i>
                
                <br/><br/>

                We build our products* to make people's lives easier. We are proud of that purpose and trust that our customers use our products for appropriate endeavors.

                Sometimes, though, we discover potential abusive usage as detailed in our <a href='#use-restrictions'>Use Restrictions policy</a>. When that happens, we investigate using the following guiding principles and process.
                <br/><br/>
                <h2>Guiding Principles</h2>
                <br/>
                <h3>Human oversight</h3>

                Who’s “we”, you ask? It’s us: folks from the Phantom Software team. Our internal abuse oversight committee includes our executives and representatives from multiple departments across the company. On rare occasions for particularly sensitive situations or if legally required, we may also seek counsel from external experts.
                <br/><br/>
                <h3>Balanced responsibilities</h3>

                We have an obligation to protect the privacy and safety of both our customers and the people reporting issues to us. We do our best to balance those responsibilities throughout the process.
                <br/><br/>
                <h3>Focus on evidence</h3>

                We base our decisions on the evidence available to us: what we see and hear account users say and do. We document what we observe and ask whether that observable evidence points to a restricted use.
                <br/><br/>
                <h2>Process</h2>

                Every case goes through the same general process:
                <ol>
                    <li>Discovery</li>
                    <li>Investigation</li>
                    <li>Decision, sometimes with right to an appeal</li>
                </ol>

                <h3>How do we discover potential abuse?</h3>

                From our experience, we learn about potential abuse because:
                <ul>
                    <li>Someone alerts us. We give <a href='#use-restrictions'>abuse reports</a> our full care and attention. Our Support team also responds to every question or comment that comes in. If we notice anything in those emails that points to a violation, we will look into it.</li>
                    <li>We notice an anomaly in our business operations monitoring. We monitor a range of things about our products, like signup volume and error rates of web requests. If we see something weird with those numbers, we get to the bottom of it.</li>
                    <li>We stumble upon public web content that links an individual or organization to a Basecamp product. We aren’t scouring the Internet looking for those links, but if we do come across any, we check them out.</li>
                </ul>

                This list is not exhaustive; there are always edge cases. We will update the list if we find regular new avenues.
                <br/><br/>
                <h3>How do we investigate?</h3>

                We focus on the evidence:
                <ul>
                    <li>Language and imagery used by users on the account</li>
                    <li>Evidence of account users’ power and/or ability to act on spoken claims</li>
                    <li>Publicly available information about account users</li>
                </ul>
                We strive to balance privacy and safety for all those involved:
                <ul>
                    <li>We make every effort to complete our investigations without accessing a customer account. For instance, if there are screenshots or public documents available, we review those. We also consider whether it is appropriate to involve the account owner in a given investigation and seek additional evidence from them.</li>
                    <li>As we review the evidence, we look for indications of existing negative impact. We also assess the severity of any potential negative impact, regardless of intent. When relevant, we look for and follow available guidelines from expert institutions.</li>
                    <li>If we cannot come to a fair assessment from the information available, we may decide to access a customer account without notice. We do not make this decision lightly. Customer privacy is a big deal to us and we only pursue this course of action if the evidence we have already is very concerning, but not definitive.</li>
                </ul>
                While some violations are flatly obvious, others are subjective, nuanced, and difficult to adjudicate. We give each case adequate time and attention, commensurate with the violation, criticality, and severity of the charge.
                <br/><br/>
                <h3>What happens if someone really broke the rules?</h3>

                We will terminate an account without advance notice if there is evidence it is being used for a restricted purpose that has, is, or will cause severe harm. If applicable, we will also report the incident to the appropriate authorities.
                <br/>
                For other cases, we’ll take a case-by-case approach to clear things up.
                <br/>
                Further, as a small, privately owned independent business that puts our values and conscience ahead of growth at all costs, we reserve the right to deny service to anyone we ultimately feel uncomfortable doing business with.
                <br/><br/>
                <h3>Can you appeal a decision?</h3>

                If we terminate an account without notice, the decision is final.
                <br/>
                For other cases, we will consider good faith appeals sent to <a href='mailto:info@phantom-soft.com'>info@phantom-soft.com</a> by the account owner within 14 calendar days.
                <br/>
                <i>*This process applies to any product created and owned by Phantom Software. That includes PhantomTutor and any upcoming products</i>
            </section>

            <br/><br/>

            Adapted from the <a href='https://github.com/basecamp/policies'>Basecamp open-source policies</a> / <a href='https://creativecommons.org/licenses/by/4.0/'>CC BY 4.0</a>
        </main>
    );
};

export default LegalPage;